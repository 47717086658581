export default [
  {
    path: "/",
    name: "default",
    meta: {
      title: "Rangel Pereira",
      authRequired: false,
    },
    component: () => import("../views/home/index.vue"),
  },
];