import { createApp } from 'vue';
import App from './App.vue';
import router from "./router";
import i18n from './i18n';
import BootstrapVue3 from 'bootstrap-vue-3';
import vClickOutside from "click-outside-vue3";
import VueFeather from 'vue-feather';
import Particles from "particles.vue3";

import '@/assets/scss/config/default/app.scss';
import '@vueform/slider/themes/default.css';

createApp(App)
    .use(router)
    .use(BootstrapVue3)
    .component(VueFeather.type, VueFeather)
    .use(Particles)
    .use(i18n)
    .use(vClickOutside).mount('#app');